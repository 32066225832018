<script>
</script>

<footer class="footer">
    <div class="footer__legal">Copyright© {new Date().getYear() + 1900} Term Finance Ltd.</div>

    <div class="footer__logos">
        <img src="images/nav_logo@2x.png" alt="logo" class="footer__logo" height="10" />
        <!-- <a href="https://fastcashcaribbean.com/" target="_blank">
            <img src="images/footer_logo_fastcash.png" alt="logo" class="footer__logo" />
        </a> -->
    </div>
</footer>

<style lang="scss">
    @use 'src/sass/base' as *;

    .footer {
        position: relative;
        overflow: hidden;
        z-index: 10;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: em(10) em(10);
        background: darken($color-secondary, 2%);
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;

        &__logos {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: em(10);
            width: 100%;
        }

        &__logo {
            cursor: pointer;
            margin-bottom: em(5);
            max-width: none;
            height: 20px;
            opacity: 0.5;
        }

        &__legal {
            width: 100%;
            margin-bottom: em(10);
            font-size: em(12);
            text-transform: uppercase;
            color: $color-black;
            opacity: 0.4;
        }

        @include for-tablet-portrait-up {
            padding: em(25) em(25);
            flex-flow: row nowrap;

            &__logos {
                justify-content: flex-end;
                width: 50%;
            }

            &__logo {
                height: 20px;
                opacity: 0.5;
            }

            &__legal {
                width: 50%;
                margin-bottom: 0;
                text-align: left;
            }
        }
    }
</style>
