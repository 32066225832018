export default {
    LOAN_STATUSES: {
        ACTIVE: 'Active',
        PENDING: 'Pending',
        PAID: 'Paid',
        PAST_DUE: 'PastDue',
        WITHDRAWN: 'Withdrawn',
        WRITTEN_OFF: 'WrittenOff',
        REVERSED: 'Reversed',
        DENIED: 'Denied',
    },

    LOAN_FILTERS: {
        ACTIVE: {
            key: 1,
            label: 'Active',
            icon: 'fa-money-bill',
            color: 'text-gray-500',
            badge: 'bg-primary',
        },
        PAID: {
            key: 2,
            label: 'Paid',
            icon: 'fa-circle-check',
            color: 'text-gray-500',
            badge: 'bg-success',
        },
        CANCELLED: {
            key: 3,
            label: 'Cancelled',
            icon: 'fa-ban',
            color: 'text-gray-500',
            badge: 'bg-error',
        },
    },
};
