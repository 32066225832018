import { DateTime } from 'luxon';

import SHARED_CONFIG from 'configs/shared';

function pmt(rate, nper, pv) {
    var pvif = Math.pow(1 + rate, -nper);
    var pmt = (pv * rate) / (1 - pvif);

    return pmt;
}

function getInterestCharge(loanType, country, amount, numPayments, daysInMonth) {
    var monthlyInterestRate = getMonthlyInterestRate(loanType, country, amount, numPayments);
    var dailyInterestRate = getDailyInterestRate(monthlyInterestRate);

    if (amount <= 0) {
        return 0;
    }

    var interestCharge = amount * daysInMonth * dailyInterestRate;

    if (interestCharge > monthlyInterestRate * amount) {
        return monthlyInterestRate * amount;
    } else {
        return interestCharge;
    }
}

function getMonthlyInterestRate(loanType, country, amount, numPayments) {
    switch (loanType) {
        case SHARED_CONFIG.LOAN_TYPES.CAR:
            if (numPayments < 6) {
                return 0.04;
            } else {
                if (amount <= 50000) {
                    return 0.035;
                } else {
                    return 0.03;
                }
            }
        case SHARED_CONFIG.LOAN_TYPES.BUSINESS:
            switch (country.key) {
                case SHARED_CONFIG.COUNTRIES.TT.key:
                    if (numPayments < 6) {
                        return 0.04;
                    } else {
                        if (amount <= 50000) {
                            return 0.035;
                        } else {
                            return 0.03;
                        }
                    }
                case SHARED_CONFIG.COUNTRIES.BB.key:
                    if (numPayments < 6) {
                        return 0.04;
                    } else {
                        if (amount <= 15000) {
                            return 0.035;
                        } else if (amount >= 25000) {
                            return 0.03;
                        }
                    }
            }
        case SHARED_CONFIG.LOAN_TYPES.PERSONAL:
            return 0.06;
        // switch (country.key) {
        //     case SHARED_CONFIG.COUNTRIES.TT.key:
        //         if (numPayments <= 12) {
        //             return 0.04;
        //         } else {
        //             return 0.03;
        //         }
        //     case SHARED_CONFIG.COUNTRIES.BB.key:
        //         if (numPayments <= 12) {
        //             return 0.05;
        //         } else if (numPayments > 12 && numPayments <= 24) {
        //             return 0.04;
        //         } else if (numPayments > 24) {
        //             return 0.03;
        //         }
        //     case SHARED_CONFIG.COUNTRIES.GY.key:
        //         if (numPayments <= 12) {
        //             return 0.05;
        //         } else {
        //             return 0.04;
        //         }
        //     case SHARED_CONFIG.COUNTRIES.JM.key:
        //         return 0.035;
        //     case SHARED_CONFIG.COUNTRIES.LC.key || SHARED_CONFIG.COUNTRIES.VC.key || SHARED_CONFIG.COUNTRIES.GD.key:
        //         if (numPayments <= 6) {
        //             return 0.05;
        //         } else if (numPayments <= 12) {
        //             return 0.04;
        //         } else {
        //             return 0.035;
        //         }
        //     case SHARED_CONFIG.COUNTRIES.AG.key:
        //         if (numPayments <= 18) {
        //             return 0.05;
        //         } else {
        //             return 0.04;
        //         }
        //     default:
        //         return 0.04;
        // }
    }
}

function getDailyInterestRate(monthlyInterestRate) {
    return (monthlyInterestRate * 12) / 365;
}

export function calculate(loanType, country, amount, numPayments) {
    let currentDate = DateTime.now(),
        daysLeftInCurrentMonth = currentDate.daysInMonth - currentDate.day,
        interestChargeForCurrentMonth = getInterestCharge(
            loanType,
            country,
            amount,
            numPayments,
            daysLeftInCurrentMonth,
        );

    var monthlyPayments = [
        {
            id: 0,
            date: currentDate.toLocaleString({ month: 'short', year: 'numeric' }),
            payment: null,
        },
    ];

    var outstandingPrincipal = amount,
        totalMonthlyPayments = 0;

    for (var i = 1; i <= numPayments; i++) {
        let date = DateTime.now().plus({ months: i }).startOf('month'),
            daysLeftInMonth = date.daysInMonth;

        // Calculate Interest
        let interestCharge = getInterestCharge(loanType, country, outstandingPrincipal, numPayments, daysLeftInMonth);

        // Calculate the Monthly Payment
        let presentValue = parseFloat(amount) + parseFloat(interestChargeForCurrentMonth);
        let monthlyPayment = pmt(
            getMonthlyInterestRate(loanType, country, amount, numPayments),
            numPayments,
            presentValue,
        );

        // Calculate the Principal Repayment
        let principalRepayment;
        if (i == 1) {
            principalRepayment = monthlyPayment - interestCharge - interestChargeForCurrentMonth;
        } else {
            principalRepayment = monthlyPayment - interestCharge;
        }

        // Calculate the new Outstanding Principal
        outstandingPrincipal -= principalRepayment;

        // Calculate the new Total Interest
        totalMonthlyPayments += monthlyPayment;

        if (i <= 6) {
            monthlyPayments.push({
                id: i,
                date: `${date.toLocaleString({ month: 'short', year: 'numeric' })} ${i == 6 ? 'and onwards' : ''}`,
                payment: monthlyPayment.toFixed(2),
            });
        }
    }

    var totalInterestCharge = totalMonthlyPayments - amount,
        effectiveInterestRate = totalInterestCharge / amount;

    return {
        amount: amount,
        monthlyPayments: monthlyPayments,
        totalInterestCharge: totalInterestCharge,
        effectiveInterestRate: effectiveInterestRate,
    };
}

export default {
    calculate,
};
