<script>
    import { onMount, onDestroy, getContext } from 'svelte';
    import { link, push, location } from 'svelte-spa-router';

    import REGISTER_CONFIG from 'configs/register';

    import infinityService from 'services/infinity';

    import authStore from 'stores/auth';
    import registerStore from 'stores/register';
    import customerStore from 'stores/customer';

    import Page from 'components/Page';
    import Button from 'components/Button';
    import Lottie from 'components/Lottie';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';
    import { ForgotPasswordModal, forgotPasswordModalOptions } from 'components/ForgotPasswordModal';

    const { open } = getContext('simple-modal');

    export let params = {};

    let isLogin;
    let isForgotPassword;
    let isAbout;
    let username, password, emailAddress, errorMsg;
    let isLoading = false;
    let activeSlideId;
    let slideshowInterval;
    let progressBarElement;

    const SLIDE_WAIT_TIME_SECONDS = 25;
    const SLIDES = [
        {
            id: 0,
            title: 'Axcel Finance',
            titleShort: 'Axcel',
            subtitle: 'Axcelerate <span>your life</span>',
            desc: 'We are passionate about transforming the lives of entrepreneurs and customers, using innovative financial solutions, tailored to your needs.',
            cta: {
                label: 'Apply for Loan',
                action: () => {
                    push('/register');
                    // isLogin = false;
                    // setTimeout(() => (isAbout = true), isLogin ? 1000 : 0);
                },
            },
        },
        // {
        //     id: 1,
        //     title: 'Loans',
        //     titleShort: 'Loans',
        //     subtitle: 'Get the right loan for your <span>family</span> or <span>small business</span>',
        //     desc: 'Axcel Finance provides responsible loans to employees and small businesses through a completely web-based system.',
        //     cta: {
        //         label: 'Apply Now',
        //         action: () => {
        //             push('/register');
        //         },
        //     },
        // },
        // {
        //     id: 2,
        //     title: 'Shop Now, Pay Later',
        //     titleShort: 'Shop',
        //     subtitle: 'Get the products you need now and <span>pay over time</span>',
        //     desc: 'Axcel Finance provides product financing through an online shop with a great variety of consumer goods.',
        //     cta: {
        //         label: 'Shop Now',
        //         url: 'https://mytfshop.com',
        //     },
        // },
        // {
        //     id: 3,
        //     title: 'XPRESS Loans',
        //     titleShort: 'XPRESS',
        //     subtitle: 'Life happens and you need cash when it does. We make it possible through Xpress Loan',
        //     desc: 'Our Xpress Loan caters to your individual needs with convenience and a hassle-free experience.',
        //     cta: {
        //         label: 'Apply Now',
        //         url: 'https://mytfcard.com',
        //     },
        // },
        // {
        //     id: 4,
        //     title: 'Invest',
        //     titleShort: 'Invest',
        //     subtitle: 'Invest your money and <span>earn great returns</span>',
        //     desc: 'Axcel Finance facilitates a variety of investment opportunities through First Citizens Investment Services.',
        //     cta: {
        //         label: 'Sign Up Now',
        //         action: () => {
        //             open(WaitlistModal, { product: 'Invest' }, waitlistModalOptions);
        //         },
        //     },
        // },
        // {
        //     id: 5,
        //     title: 'Financial Wellness',
        //     titleShort: 'Well',
        //     subtitle: 'Get financial guidance from <span>people who care</span>',
        //     desc: 'Axcel Finance offers Financial Wellness seminars and personal consultations to our customers at no charge.',
        //     cta: {
        //         label: 'Learn More',
        //         url: 'https://wellness.mytermfinance.com',
        //     },
        // },
    ];

    onMount(() => {
        isAbout = false;
        isLogin = $location == '/login';
        isForgotPassword = $location == `/reset-password/${params.token}`;
        username = $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].username;
        password = $registerStore[REGISTER_CONFIG.STEPS.CREDENTIALS.key].password;

        // Automatically log in the user if they just completed registering
        setTimeout(() => {
            if (username && password && $registerStore.isComplete) {
                $registerStore.reset();
                login();
            }
        }, 1000);

        // Start the slideshow
        activeSlideId = SLIDES[0].id;
        // startSlideshow();

        // Show forgot password modal
        if (isForgotPassword) {
            open(ForgotPasswordModal, { token: params.token }, forgotPasswordModalOptions);
        }
    });

    onDestroy(() => {});

    function startSlideshow() {
        slideshowInterval = setInterval(changeSlide, SLIDE_WAIT_TIME_SECONDS * 1000);
    }

    function changeSlide(slideId = null) {
        let nextSlideId;

        if (slideId != null) {
            clearInterval(slideshowInterval);
            activeSlideId = slideId;
            resetProgressBarAnimation();
            startSlideshow();
        } else {
            nextSlideId = activeSlideId + 1;
            activeSlideId = SLIDES[nextSlideId >= SLIDES.length ? 0 : nextSlideId].id;
        }
    }

    function resetProgressBarAnimation() {
        progressBarElement.classList.remove('split__progress--fill');
        progressBarElement.offsetHeight; /* trigger reflow */
        progressBarElement.classList.add('split__progress--fill');
    }

    function login() {
        isLoading = true;
        errorMsg = null;

        infinityService.login({ username, password }).then(
            (response) => {
                if (response.isSuccessful) {
                    authStore.setCustomerId(response.customerId);

                    // Fetch the rest of the customer details
                    customerStore.fetch(response.customerId).then(
                        () => {
                            push('/loans');
                        },
                        () => {
                            errorMsg = 'We were not able to log you in. Please contact support if this issue persists.';
                            isLoading = false;
                        },
                    );
                } else {
                    errorMsg = response.errorMsg;
                    isLoading = false;
                }
            },
            (error) => {
                console.error(error);
                isLoading = false;
            },
        );
    }

    // async function handleForgotPassword() {
    //     if (emailAddress) {
    //         let status = await infinityService.forgotPassword({
    //             emailAddress,
    //         });
    //     } else {
    //     }
    // }
</script>

<div class="root">
    <div class="reveal animate__animated animate__slideOutLeft" />
    <Page>
        <div slot="content">
            <div class="split">
                <div class="split__container">
                    <div class="wrapper">
                        <div class="split__bg split__bg--overlay" />
                        {#each SLIDES as slide, i}
                            <div
                                class="split__bg split__bg--mobile animate__animated"
                                class:animate__fadeIn={activeSlideId == slide.id}
                                class:animate__fadeOut={activeSlideId != slide.id}
                                style={`background-image: url(/images/bg_${slide.id}.jpg); z-index: ${SLIDES.length - i};`}
                            />
                        {/each}
                        <nav class="nav">
                            <div class="nav__logo">
                                <img
                                    style="display: inline-block;"
                                    src={`/images/nav_logo${isLogin || isAbout ? '_white' : ''}@2x.png`}
                                    height="40"
                                    alt="logo"
                                />
                                <!-- <img
                                    style="display: inline-block;"
                                    src={`/images/nav_logo_fc${isLogin || isAbout ? '_white' : ''}@2x.png`}
                                    height="60"
                                    alt="logo"
                                /> -->
                            </div>
                            <div class="nav__logo nav__logo--mobile" class:nav__logo--about={isAbout}>
                                <img
                                    style="display: inline-block;"
                                    src={`/images/nav_logo_white@2x.png`}
                                    height="40"
                                    alt="logo"
                                />
                            </div>
                            <div class="nav__links" class:nav__links--white={isLogin || isAbout}>
                                <div
                                    class="nav__link"
                                    on:keydown
                                    on:click={() => {
                                        setTimeout(() => (isAbout = true), isLogin ? 1000 : 0);
                                        isLogin = false;
                                    }}
                                >
                                    About
                                </div>
                                <a use:link href="/register" target="_blank" class="nav__link">Loans</a>
                            </div>
                            <div class="nav__mobile-links">
                                <div class="nav__link nav__link--mobile">
                                    {#if !isLogin && !isAbout}
                                        <Button
                                            secondaryInverse
                                            small
                                            onClick={() => {
                                                open(SupportModal, {}, supportModalOptions);
                                            }}
                                        >
                                            Contact Us
                                        </Button>
                                    {/if}
                                </div>
                                <div class="nav__link nav__link--mobile">
                                    {#if !isLogin && !isAbout}
                                        <Button
                                            primaryInverse
                                            small
                                            onClick={() => {
                                                setTimeout(() => (isLogin = true), isAbout ? 1000 : 0);
                                                isAbout = false;
                                            }}
                                        >
                                            Log In
                                        </Button>
                                    {/if}
                                </div>
                            </div>
                        </nav>

                        <!-- Login / Forgot Password -->
                        <div
                            class="slide-overlay slide-overlay--login animate__animated"
                            class:animate__slideInLeft={isLogin}
                            class:animate__slideOutLeft={!isLogin}
                        >
                            <div
                                class="slide slide--no-top slide--center animate__animated animate__fadeIn"
                                style="animation-delay: 1s;"
                            >
                                <div class="slide__content slide__content--compact">
                                    <div class="slide__header">
                                        <h1
                                            class="slide__title slide__title--no-margin slide__title--extra-line-height slide__title--light animate__animated animate__pulse"
                                        >
                                            Log in
                                        </h1>
                                        <div
                                            class="overlay-close-btn animate__animated animate__fadeIn"
                                            on:keydown
                                            on:click={() => {
                                                isLogin = false;
                                            }}
                                        >
                                            <Lottie jsonPath="/lottie/close_icon.json" loop={false} />
                                        </div>
                                    </div>
                                    <form class="login-form">
                                        {#if errorMsg}
                                            <div class="login-form__errors">{errorMsg}</div>
                                        {/if}
                                        {#if !isLoading}
                                            <div class="login-form__control">
                                                <input
                                                    autocomplete="off"
                                                    type="text"
                                                    class="login-form__input"
                                                    placeholder="Username"
                                                    bind:value={username}
                                                />
                                            </div>
                                            <div class="login-form__control">
                                                <input
                                                    autocomplete="off"
                                                    class="login-form__input"
                                                    type="password"
                                                    placeholder="Password"
                                                    bind:value={password}
                                                />
                                            </div>
                                        {:else}
                                            <div class="login-form__control animate__animated animate__bounceIn">
                                                <div class="login-form__loader">
                                                    <Lottie jsonPath="/lottie/loading-circle.json" />
                                                </div>
                                            </div>
                                        {/if}
                                        <div class="login-form__actions">
                                            <Button
                                                type="submit"
                                                primaryInverse
                                                fullWidth
                                                onClick={() => login()}
                                                isLoading
                                                disabled={isLoading || !username || !password}
                                            >
                                                {isLoading ? 'Logging in ...' : 'Log in'}
                                            </Button>
                                        </div>
                                        <div class="login-form__links">
                                            <div class="login-form__link">
                                                <div
                                                    on:keydown
                                                    on:click={() => {
                                                        open(ForgotPasswordModal, {}, forgotPasswordModalOptions);
                                                    }}
                                                >
                                                    I forgot my password
                                                </div>
                                            </div>
                                            <div class="login-form__link">
                                                Not a member?
                                                <a use:link href="/register">Register today</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- About -->
                        <div
                            class="slide-overlay slide-overlay--about animate__animated"
                            class:animate__slideInLeft={isAbout}
                            class:animate__slideOutLeft={!isAbout}
                        >
                            <div
                                class="slide slide--no-top slide--center animate__animated animate__fadeIn"
                                style="animation-delay: 1s;"
                            >
                                <div class="slide__content slide__content--compact slide__content--about">
                                    <div class="slide__header">
                                        <h1
                                            class="slide__title slide__title--no-margin slide__title--light slide__title--extra-line-height animate__animated animate__pulse"
                                        >
                                            About
                                        </h1>
                                        <div
                                            class="overlay-close-btn animate__animated animate__fadeIn"
                                            on:keydown
                                            on:click={() => (isAbout = false)}
                                        >
                                            <Lottie jsonPath="/lottie/close_icon.json" loop={false} />
                                        </div>
                                    </div>
                                    <p class="slide-overlay__p">
                                        Axcel Finance is dedicated to serving the Caribbean community with easy access
                                        to financing.
                                    </p>
                                    <p class="slide-overlay__p">
                                        We are passionate about transforming the lives of entrepreneurs and customers,
                                        using innovative financial solutions, tailored to your needs.
                                    </p>
                                    <br />
                                    <Button
                                        primary
                                        medium
                                        onClick={() => {
                                            open(SupportModal, {}, supportModalOptions);
                                        }}
                                    >
                                        Contact us
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {#each SLIDES as slide}
                            {#if activeSlideId == slide.id}
                                <div class="slide animate__animated animate__fadeIn">
                                    <div class="slide__content">
                                        <h1 id="slide__title" class="slide__title">
                                            {slide.title}
                                        </h1>
                                        <div class="slide__subtitle animate__animated animate__fadeIn">
                                            {@html slide.subtitle}
                                        </div>
                                        <div class="slide__desc animate__animated animate__fadeIn">
                                            {@html slide.desc}
                                        </div>
                                        <div class="slide__cta animate__animated animate__fadeIn">
                                            {#if slide.cta.url || slide.cta.action}
                                                <Button
                                                    primary
                                                    medium
                                                    onClick={() => {
                                                        if (slide.cta.action) {
                                                            slide.cta.action();
                                                        } else {
                                                            window.open(slide.cta.url);
                                                        }
                                                    }}
                                                >
                                                    {slide.cta.label}
                                                </Button>
                                            {/if}
                                        </div>
                                    </div>
                                </div>
                            {/if}
                        {/each}
                        <div class="split__progress" />
                        <div
                            bind:this={progressBarElement}
                            class="split__progress split__progress--fill"
                            style={`animation-duration: ${SLIDE_WAIT_TIME_SECONDS}s`}
                        />
                    </div>
                </div>
                <div class="split__container split__container--bg">
                    <div
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; opacity: 0.5; z-index: 10;"
                    >
                        <Lottie jsonPath="/lottie/circle_bg.json" />
                    </div>
                    <div class="wrapper wrapper--justify-start wrapper--reverse">
                        <nav class="nav nav--reverse">
                            <div class="nav__links nav__links--small-gap">
                                <div class="nav__link">
                                    <Button
                                        secondaryInverse
                                        small
                                        disabled={isLogin}
                                        onClick={() => {
                                            open(SupportModal, {}, supportModalOptions);
                                        }}
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                                <div class="nav__link">
                                    <Button
                                        primary
                                        small
                                        disabled={isLogin}
                                        onClick={() => {
                                            setTimeout(() => (isLogin = true), isAbout ? 1000 : 0);
                                            isAbout = false;
                                        }}
                                    >
                                        Log In / Register
                                    </Button>
                                </div>
                            </div>
                        </nav>
                    </div>
                    {#each SLIDES as slide, i}
                        <div
                            class="split__bg animate__animated"
                            class:animate__fadeIn={activeSlideId == slide.id}
                            class:animate__fadeOut={activeSlideId != slide.id}
                            style={`background-image: url(/images/bg_${slide.id}@2x.jpg); z-index: ${SLIDES.length - i};`}
                        />
                    {/each}
                </div>
            </div>
        </div>
    </Page>
</div>

<style lang="scss" type="text/scss">
    @use 'src/sass/base' as *;

    .root {
        background: $color-primary;
        height: 100vh;
        overflow: scroll;
    }

    .reveal {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999999999;
        background: $color-primary;
        animation-duration: 0.5s;
        animation-delay: 0.25s;
    }

    .split {
        // Mobile
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: $color-secondary;

        &__container {
            overflow: hidden;
            z-index: 2;
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            position: relative;
            width: 60%;
            height: 100%;
            background: url('/images/login_bg.png') no-repeat center right;
            background-size: cover;
            box-shadow:
                rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

            &--bg {
                // Mobile
                display: none;

                overflow: hidden;
                position: relative;
                z-index: 1;
                justify-content: flex-start;
                align-items: center;
                max-width: 800px;
                animation: fadeIn;
                animation-duration: 2s;
            }
        }

        &__bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            &--mobile {
                display: block;
            }

            &--overlay {
                display: block;
                z-index: 10;
                background: rgba($color-black, 0.3);
            }
        }

        @keyframes progress {
            from {
                height: 0;
            }
            to {
                height: 100%;
            }
        }

        &__progress {
            position: absolute;
            z-index: 50;
            top: 0;
            right: 0;
            width: 5px;
            height: 100%;
            background: darken($color-secondary, 5%);

            &--fill {
                animation-name: progress;
                animation-direction: normal;
                animation-iteration-count: infinite;
                background: $color-primary;
                animation-timing-function: ease-in-out;
            }
        }

        @include for-tablet-landscape-up {
            &__container {
                padding-bottom: 10px;
                padding-top: 10px;

                &--bg {
                    display: flex;
                }
            }

            &__bg {
                &--mobile {
                    display: none;
                }

                &--overlay {
                    display: none;
                }
            }
        }
    }

    .wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        width: 100%;
        padding: em(25);
        font-family: $ff-headers;

        &--justify-start {
            justify-content: flex-start;
        }
    }

    .nav {
        // Mobile
        justify-content: space-between;

        position: relative;
        z-index: 1000;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        width: 100%;
        min-height: 60px;

        &--reverse {
            justify-content: flex-end;
        }

        &__logo {
            display: none;
            white-space: nowrap;
            // margin-right: em(50);
            min-width: 60px;

            img {
                max-width: none;
            }

            &--mobile {
                display: block;
            }

            &--about {
                display: none;
            }
        }

        &__links {
            // Mobile
            display: none;

            column-gap: em(35);
            color: $color-white;

            &--small-gap {
                column-gap: em(15);
            }

            &--white {
                color: $color-white;
            }
        }

        &__link {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: em(16);
            color: inherit;
            cursor: pointer;

            &--mobile {
                margin-left: em(15);
                display: flex;
            }
        }

        &__mobile-links {
            display: flex;
            flex-flow: row nowrap;
        }

        @include for-tablet-portrait-up {
            justify-content: flex-start;
            flex-flow: row nowrap;

            &__logo {
                margin-right: em(50);
            }

            &--reverse {
                justify-content: flex-end;
            }

            &__links {
                display: flex;
                color: $color-white;
            }

            &__link {
                &--mobile {
                    display: none;
                }
            }
        }

        @include for-tablet-landscape-up {
            &__logo {
                display: block;

                &--mobile {
                    display: none;
                }
            }

            &__link {
                &--mobile {
                    display: none;
                }
            }

            &__links {
                color: $color-white;

                &--white {
                    color: $color-white;
                }
            }
        }
    }

    .slide {
        // Mobile
        top: em(-75);
        padding: 0 em(10);

        position: relative;
        z-index: 10;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 100%;

        &--center {
            justify-content: center;
            align-items: center;
            padding: 0;
        }

        &--no-top {
            top: em(0);
        }

        &__content {
            width: 100%;

            &--compact {
                width: 100%;
                max-height: 95vh;
                max-width: em(700);
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: em(25);
        }

        &__title {
            // Mobile
            font-size: em(56);
            line-height: 1;
            margin-bottom: em(20);

            display: block;
            margin-bottom: em(8);
            font-weight: $fw-bold;
            line-height: 1;
            color: $color-white;
            animation-iteration-count: infinite;
            animation-duration: 4s;

            &--light {
                color: $color-white !important;
            }

            &--no-margin {
                margin-bottom: 0;
            }

            &--extra-line-height {
                line-height: 1.3;
            }
        }

        &__subtitle {
            // Mobile
            font-size: em(28);

            color: $color-accent;
            margin-bottom: em(15);
            font-weight: $fw-regular;
            line-height: 1.1;
            animation-delay: 0.5s;
        }

        &__desc {
            // Mobile
            font-size: em(18);
            color: $color-white;

            margin-bottom: em(20);
            font-family: $ff-primary;
            font-weight: $fw-regular;
            line-height: 1.4;
            animation-delay: 0.9s;
        }

        &__cta {
            animation-delay: 1.2s;
        }

        @include for-tablet-portrait-up {
            top: 0;
            max-width: em(800);
            padding: 0 em(40);

            &__content {
                &--compact {
                    margin-top: 0;
                }
            }

            &__title {
                margin-bottom: em(3);
                font-size: em(90);
                line-height: 1.3;
            }

            &__subtitle {
                margin-bottom: em(5);
                font-size: em(42);
            }

            &__desc {
                display: block;
                font-size: em(24);
            }
        }

        @include for-tablet-landscape-up {
            max-width: none;

            &__title {
                background: linear-gradient(119deg, rgba(#d83e33, 1) 0%, rgba(#fff, 1) 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;

                &--extra-line-height {
                    line-height: 1.3;
                }

                &--light {
                    color: $color-white !important;
                    background-clip: none;
                    -webkit-text-fill-color: $color-white;
                }
            }

            &__subtitle {
                color: $color-white;
            }

            &__desc {
                color: $color-gray-text;
            }
        }
    }

    .slide-icons {
        // Mobile
        top: em(-125);
        justify-content: center;
        padding: 0;

        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        width: 100%;

        &__icon {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            opacity: 1;
            cursor: pointer;
            filter: saturate(0) brightness(200%) !important;

            &--active {
                animation: bounceIn;
                animation-duration: 2s;
                opacity: 1;
            }

            &:hover {
                opacity: 1;
                animation: pulse;
                animation-duration: 0.25s;
            }
        }

        &__label {
            margin-bottom: em(10);
            font-size: em(12);
            color: $color-white;
            white-space: nowrap;
        }

        &__animation {
            overflow: hidden;
            width: 35px;
            height: 35px;
            padding: em(5);
            background: rgba($color-secondary, 0.8);
            border: 1px solid $color-primary;
            border-radius: 50%;
        }

        &__divider {
            position: relative;
            top: 8px;
            width: 50px;
            height: 2px;
            background: $color-white;
            opacity: 0.25;
        }

        @include for-tablet-portrait-up {
            top: 0;
        }

        @include for-tablet-landscape-up {
            justify-content: flex-start;
            padding: 0 em(40);

            &__icon {
                background: transparent;
                opacity: 0.5;

                &--active {
                    animation: bounceIn;
                    animation-duration: 2s;
                    opacity: 1;
                }
            }

            &__divider {
                background: $color-primary;
                opacity: 0.5;
            }

            &__label {
                color: $color-gray-text;
            }
        }
    }

    .slide-overlay {
        // Mobile
        width: 100%;
        padding: 0 em(25);
        overflow-y: scroll;
        color: $color-white;

        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        background: $color-primary;
        background-size: cover;
        box-shadow:
            rgba(0, 0, 0, 0.25) 0px 54px 55px,
            rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px,
            rgba(0, 0, 0, 0.09) 0px -3px 5px;
        animation-duration: 0.25s;

        &--login {
            background: url('/images/login_form_bg.png') no-repeat center;
            background-size: cover;
        }

        &--about {
            z-index: 2000;
            background: url('/images/about_us_bg.png') no-repeat center;
            background-size: cover;
        }

        &__p {
            margin-bottom: em(20);
            line-height: 1.2;
            font-family: $ff-primary;
            font-size: em(24);
            font-weight: $fw-regular;
            color: $color-white;
        }

        @include for-tablet-portrait-up {
            overflow-y: auto;

            &--about {
                z-index: 100;
            }
        }

        @include for-tablet-landscape-up {
            width: 98%;
            padding: 0;

            &--login {
                background: url('/images/login_form_bg.png') no-repeat center;
                background-size: cover;
            }

            &--about {
                background: url('/images/about_us_bg.png') no-repeat center;
                background-size: cover;
            }
        }
    }

    .login-form {
        width: 100%;

        &__errors {
            margin: em(25) 0;
            font-family: $ff-primary;
            font-size: em(24);
            color: $color-white;
        }

        &__control {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-start;
            align-items: center;
            margin: em(10) 0;
        }

        &__actions {
            margin: em(15) 0;
        }

        &__input {
            width: 100%;
            margin: 0;
            padding: em(13) em(15);
            outline: none;
            border: none;
            border-radius: em(22);
            box-shadow: none;
            background: rgba($color-white, 0.2);
            font-size: em(20);
            font-weight: $fw-regular;
            color: $color-white;
            cursor: pointer;

            &::placeholder {
                color: $color-secondary;
                opacity: 0.5;
            }

            &:focus {
                animation: pulse;
                animation-duration: 0.25s;
            }

            &:hover {
                background: rgba($color-white, 0.3);
            }
        }

        &__links {
            margin-top: em(50);
        }

        &__link {
            font-size: em(20);
            font-weight: $fw-light;
            color: $color-secondary;

            a,
            div {
                display: inline-block;
                margin-bottom: em(10);
                font-weight: $fw-bold;
                color: $color-white;
                cursor: pointer;

                &:hover {
                    animation: pulse;
                    animation-duration: 0.25s;
                    color: #cf57ff;
                }
            }
        }

        &__loader {
            display: flex;
            justify-items: center;
            align-items: center;
            width: 100px;
        }
    }

    .overlay-close-btn {
        position: relative;
        z-index: 2000;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 50px;
        cursor: pointer;

        &:hover {
            animation: pulse;
            animation-duration: 0.25s;
            animation-iteration-count: infinite;
        }
    }
</style>
