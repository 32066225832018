<script>
    import LOANS_CONFIG from 'configs/loans';

    import { formatCurrency } from 'services/util';

    import Pill from 'components/Pill';
    import LoanCardStatus from 'components/LoanCard/LoanCardStatus';

    export let loan;
    export let isSelected = false;
    export let onClick = () => {};

    function getLoanProgress() {
        return (loan.amountPaid / loan.amount) * 100;
    }
</script>

<div
    class="flex h-full w-full cursor-pointer flex-col items-center justify-between rounded-md border border-neutral bg-white px-4 py-4 transition-all hover:border-primary hover:shadow-sm"
    class:!border-primary={isSelected}
    class:shadow-md={isSelected}
    on:keydown
    on:click={onClick}
>
    <!-- {#if loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING} -->
    <div class="relative mb-4 h-[7px] w-full overflow-hidden rounded-md bg-neutral">
        <div class="absolute left-0 top-0 h-full rounded-md bg-success" style={`width: ${getLoanProgress()}%`} />
    </div>
    <!-- {/if} -->
    <div class="mb-6 flex w-full items-center justify-between">
        <div class="text-2xl font-medium text-secondary">{formatCurrency(loan.amount, 'TT', 'USD')}</div>
        <div class="flex items-center justify-center gap-x-2">
            <LoanCardStatus status={loan.status} />
            {#if !loan.customerSignature && (loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING)}
                <Pill urgent>Needs Signature</Pill>
            {/if}
        </div>
    </div>
    <div class="space-between flex w-full items-center">
        <div class="w-full whitespace-nowrap text-sm">
            <div class="space-x-0.5 text-gray-500">
                <span><i class="fa fa-regular fa-circle-check text-success"></i></span>
                <span>Paid</span>
            </div>
            <div class="text-base font-medium text-secondary">{formatCurrency(loan.amountPaid, 'TT', 'USD')}</div>
        </div>
        <div class="w-full whitespace-nowrap text-sm">
            <div class="space-x-0.5 text-gray-500">
                <span><i class="fa fa-regular fa-exclamation-circle text-error"></i></span>
                <span>Outstanding</span>
            </div>
            <div class="text-base font-medium text-secondary">{formatCurrency(loan.amountDue, 'TT', 'USD')}</div>
        </div>
        <div class="w-full whitespace-nowrap text-sm">
            <div class="space-x-0.5 text-gray-500">
                <span><i class="fa fa-regular fa-calendar text-warning"></i></span>
                <span>Next Payment</span>
            </div>
            <div class="text-base font-medium text-secondary">{loan.dueDate ? loan.dueDate : 'N/A'}</div>
        </div>
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
